<template>
  <v-card>
    <v-toolbar color="primary" class="text-white rounded-0" height="80px">
      <v-toolbar-title class="ml-3">
        <h2>Lead Raporları</h2>
      </v-toolbar-title>
      <KTTopMenu />
    </v-toolbar>
    <v-toolbar color="indigo" class="text-white">
      <div style="display: flex; width: 100%; gap: 10px">
        <v-btn
          v-for="buttons in this.reportLists"
          :key="buttons.key"
          small
          color="primary"
          @click="() => handleReportButton(buttons.key)"
        >
          {{ buttons.name }}
        </v-btn>
        <v-row>
          <v-col class="p-0" offset="9" cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :hide-details="true"
                  v-model="dateRangeText"
                  style="margin-top: 15px"
                  label="Tarih Aralığı"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dark
                  range
                ></v-text-field>
              </template>
              <v-date-picker v-model="rangeDates" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-toolbar>
    <v-card-text>
      <div v-if="['pageLoading', 'loaded'].includes(reportState)">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItem"
          :items-per-page="perPage"
          :loading="reportState === 'pageLoading'"
          @update:options="pagination"
        >
        </v-data-table>
      </div>
      <v-card-text v-else-if="reportState === 'loading'">
        Rapor yükleniyor, lütfen bekleyin!
      </v-card-text>
      <v-card-text v-else> Lütfen bir rapor seçin! </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import KTTopMenu from "@/view/layout/header/TopMenu.vue";
import moment from "moment";

const REPORT_LISTS = [
  {
    key: "agent-lead-status",
    name: "Agent Lead Status",
  },
];

export default {
  name: "ReportsList",
  components: { KTTopMenu },
  data() {
    return {
      menu: false,
      currentReport: "",
      reportState: false,
      reportLists: REPORT_LISTS,
      headers: [],
      items: null,
      currentPage: 1,
      totalItem: 0,
      perPage: 0,
      rangeDates: [
        moment().subtract(1, "month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    };
  },
  computed: {
    dateRangeText() {
      return this.rangeDates.join(" -- ");
    },
  },
  methods: {
    /**
     * Get given report
     *
     * @param {string} reportId
     */
    async getReport() {
      this.reportState = !this.items ? "loading" : "pageLoading";

      const {
        status,
        data: { data, total, per_page },
      } = await this.$apiService.post("reports", {
        page: this.currentPage,
        report: this.currentReport,
        start_date: this.rangeDates[0],
        end_date: this.rangeDates[1],
      });

      if (!status === 200) return;

      this.setHeadersFromData(data[0]);

      this.items = data || null;
      this.totalItem = total;
      this.perPage = per_page;
      this.reportState = "loaded";
    },

    /**
     * Set datatable header from data
     *
     * @param {object} data
     */
    setHeadersFromData(data) {
      if (!data) return;

      this.headers = Object.keys(data).map((title) => {
        return {
          value: title,
          text: title,
        };
      });
    },

    /**
     * Paginate server side
     *
     * @param {number} page
     */
    pagination({ page }) {
      this.currentPage = page;

      this.getReport();
    },

    /**
     * @param {string} report
     */
    handleReportButton(report) {
      this.currentReport = report;

      this.getReport();
    },
  },
};
</script>
